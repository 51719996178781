/**********************************
Description...: MOBILE THEME
Last Changed..: 31-05-2019
Notes #1......:
/******************/

@import "variables.scss";
@import "mixins.scss";

@media screen and (min-width:768px) {
	.flexible--template .hero .slide { padding: 20vh 0; }
	.flexible--template .review--container { font-size: 1.1rem; padding: 2.5%; };
	.flexible--template .upcoming--events { padding: 3rem; }


	.flexible--template .hero--title.lightBlueTitle {
		
		float: left;
		margin-left: -6rem;
		
	}

	.flexible--template .hero--subtitle.darkBlueTitle {
		
		float: right;
		margin-top: -2rem;
		margin-right: -6rem;
		clear: both;

	}

}

@media screen and (min-width:640px) {

	.main-content { 

		.biography-panel {
	
			&.active {
		
				display: flex;
				padding-top: 0;
				padding-bottom: 0;

			}

		}
		
	}

}

@media screen and (min-width:1000px) {
	.flexible--template .hero .slide { padding: 22.5vh 0; }
}

@media screen and (min-width:1400px) {
	.flexible--template .hero .slide { padding: 30vh 0; }
}

@media screen and (max-device-width:1280px), screen and (max-width:1280px) {
	/** SUPER SIZE MONITOR **/


	body {-webkit-text-size-adjust:none;-ms-text-size-adjust:100%;}
	video {width:100%;}
	.container, .cont-header, .cont-footer {width:auto;}

	.banner-hero img {width:100%;}

	#main-content_holder header h1 {min-width:31.57%;@include border-box;}

	.main-content {width:100%;@include border-box;}
		.split-col main {width:65.87%;}
		#aside {width:31.57%;}

	.preview-block {width:23%;margin-right:2.666%;@include border-box;}
	.preview-block:last-child {width:23%;}
	.preview-block h6 {margin-left:0;margin-right:0;}

	/*about*/
	.main-content .item-block {width:23%;margin-right:2.666%;@include border-box;}
}

@media screen and (max-device-width:1100px), screen and (max-width:1100px) {


	.flexible--template .hero .slide { padding: 20vh 0; }

	.container, .cont-header, .cont-footer {padding:0 24px;}

	#nav ul li {font-size:1em;}

	/*homepage*/
	.preview-block {padding:0;}
	.preview-home .preview-block h3 {margin:10px 0 20px;}

	.main-content .item-block {width:48%;margin-right:4%;}
	.main-content .item-block:nth-child(even) {margin-right:0;}
}

@media screen and (max-device-width:960px), screen and (max-width:960px) {
	/** TABLETS **/

	.flexible--template .hero .slide { padding: 20vh 0; }

	#header {padding:20px 0 !important;}
		#top {min-height:56px !important;}
		#header img, #header object {width:160px;}
		#header img.shrink, #header object.shrink {width:160px !important;margin-top:0 !important;}
			#nav ul li {font-size:0.9em;}
			#nav ul li a {margin-left:6px;}

	#aside ul {width:90%;}
	#aside p {font-size:1.25em;line-height:1.3em;}

	.preview-holder h5 {font-size:2.15em;}

	.embed-container {width:auto;height:auto;padding-bottom:56.25%;}

	/*contact forms*/
	#contact-form ul,
	.gform_wrapper ul, .gform_wrapper .gform_footer {margin:0;}
	#contact-form ul.options, .gform_wrapper ul.gfield_checkbox {margin-left:0;margin-right:0;}
	#contact-form ul.options li label, .gform_wrapper ul.gfield_checkbox li label {max-width:370px;}

	/*homepage*/
	#home .camera_caption {bottom:-40% !important;}
	/*#camera_wrap_1 {height:340px !important;}
	.camera_wrap img {
	min-width:100% !important;width:100% !important;height:auto !important;
	margin-right:0 !important;margin-left:0 !important;
	}*/
	//.camera_caption {max-width:300px;top:7%;margin-left:5%;}

	.home-quote {padding:40px 46px;}
	.home-quote blockquote {font-size:1.75em;line-height:1.3em;padding:0;}
}

@media screen and (max-device-width:767px), screen and (max-width:767px) {
	/** Smaller TABLETS **/

	.flexible--template .hero .slide { padding: 15vh 0; }

	.cont-header {padding:0;}

	body {padding-top:0 !important;}
	#top {flex-direction:column;}
	#header {position:relative !important;padding:12px 0 0 !important;}
		#header img, #header object {margin-left:24px;}

		#nav #pull {display:block;}
		#nav {float:left;padding:0 24px;background-color:darken($dark-blue,11%);
		border-top:12px solid #fff;@include border-box;
		-webkit-background-clip:padding-box;//webkit, pls.
		}
			#nav ul {display:none;float:none;padding:16px 0 20px;}
			#nav ul li {width:100%;font-size:1.1em;}
			#nav ul li a {width:100%;padding:14px 0 10px;margin-left:0;
			color:#fff;font-weight:600;
			border:0;border-bottom:1px solid #236e84;
			@include border-box;}
			#nav ul li:last-child a {border-bottom:0;}
			#nav ul li a:hover {color:lighten($dark-blue,30%);}
			#nav .mobnav-subarrow {display:block;}

	#main-content_holder .skip-subnav {display:block;}
		#main-content_holder .container {padding-left:0;padding-right:0;}
			#main-content_holder header,
			#main-content_holder .skip-subnav,
			.split-col main,
			#aside,
			#main-content_holder .preview-holder[role="complementary"] .container {
			padding-left:24px;padding-right:24px;@include border-box;}

		#main-content_holder .main-content header,
		#main-content_holder #aside .skip-subnav {padding-left:0;padding-right:0;}

	#main-content_holder header h1 {font-size:1.7em;}

	.split-col main, #aside, #aside ul {width:100%;}

	#aside {min-height:1px;margin-top:0;padding-bottom:30px;border-top:6px solid $base-blue;}
		#aside address {margin-top:30px;}

	.preview-holder .main-content {padding-bottom:0;}
		.preview-block {width:48%;margin-right:4%;margin-bottom:30px;}
		.preview-block:last-child {width:48%;}
		.preview-block:nth-child(odd) {margin-right:0;}

	/*homepage*/
	#home .container, .home-quote {padding-left:24px;padding-right:24px;}
	#home .cont-header {padding-left:0;padding-right:0;}
	.preview-home .preview-block:nth-child(2n+1) {margin-right:4%;}
	.preview-home .preview-block:nth-child(2n) {margin-right:0;}

	#home .camera_caption {font-size:1.25em;line-height:1.7em;}
	#home .camera_caption > div {min-width:550px;margin-left:-280px;}
	#home .camera_caption span {min-height:60px;font-size:1.5em;line-height:60px;}
}

@media screen and (max-device-width:640px), screen and (max-width:640px) {

	/** Most SMARTPHONEs **/

	.main-content { 

		.member img.biography-image {
			
			float: left;
			width: 100%;
			max-width: 100%;
			min-width: 100%;
			margin-right: 0;

		}

	}

	.flexible--template .hero .slide .slide--content {
		// position: relative;
	 //    left: auto;
	 //    top: auto;
	 //    transform: translateX(0) translateY(0);
	}

	.flexible--template .hero .lightBlueTitle, 
	.flexible--template .hero .darkBlueTitle {
		font-size: 1.2rem;
	}

	.container, .cont-footer {padding:0 14px;}
	.cont-header {padding:0;}

	#header img, #header object {margin-left:14px;}
		#nav {padding:0 14px;}

	.banner-hero {border-bottom-width:14px;}

	#main-content_holder {font-size:85%;}
		#main-content_holder header,
		#main-content_holder .skip-subnav,
		.split-col main,
		#aside,
		#main-content_holder .preview-holder[role="complementary"] .container {
		padding-left:14px;padding-right:14px;@include border-box;}

	#main-content_holder header {margin-top:0;margin-left:-14px;}
		#main-content_holder .main-content header {margin-left:0;}
	#main-content_holder header h1 {min-height:1px;line-height:normal;padding:6px 30px 6px 18px;}
	#main-content_holder header h1::after {left:-40px;}
	#main-content_holder header h1::before {display:none;}
	.main-content {margin-top:0;}

	.main-content img {float:none;max-width:100%;height:auto;margin:20px 0;@include border-box;}
	.main-content blockquote {margin-left:0;}

	.btn a {height:42px;line-height:42px;}

	.preview-block img {margin:0 0 10px;}

	#footer {font-size:80%;}

	/*contact forms*/
	#contact-form, .gform_wrapper, #respond {padding:14px;}
	#contact-form ul li,
	#contact-form input[type="text"], #contact-form input[type="tel"],
	#contact-form input[type="email"], #contact-form input[type="url"],
	#contact-form input[type="file"], #contact-form textarea,
	.gform_wrapper ul li,
	.gform_wrapper input[type="text"], .gform_wrapper input[type="tel"],
	.gform_wrapper input[type="email"], .gform_wrapper input[type="url"],
	.gform_wrapper input[type="file"], .gform_wrapper textarea,
	#respond ul li,
	#respond input[type="text"], #respond input[type="tel"],
	#respond input[type="email"], #respond input[type="url"],
	#respond input[type="file"], #respond textarea, #respond p {
	width:100%;@include border-box;}
	#contact-form ul.options li label, .gform_wrapper ul.gfield_checkbox li label {max-width:270px;}
	#contact-form h5, .gform_wrapper h3, #respond h3 {margin-bottom:20px;}
	#contact-form fieldset, .gform_wrapper .gform_body {margin-bottom:0;}

	/*homepage*/
	#home .container, .home-quote {padding-left:14px;padding-right:14px;}
	#home .cont-header {padding-left:0;padding-right:0;}
	.home-intro {padding:30px 0;}
		.home-intro p {font-size:1.2em;}
		.home-quote blockquote {font-size:1.4em;}

	#home .camera_caption {font-size:1.05em;line-height:1.5em;}
	#home .camera_caption > div {min-width:280px;margin-left:-165px;}
	#home .camera_caption span {min-height:40px;font-size:1.3em;line-height:40px;padding:0 10px;}
	#home .camera_caption span:after {border-left-width:20px;border-bottom-width:14px;}
	#home .camera_caption span.alt:after {border-right-width:20px;border-bottom-width:14px;}
}

@media screen and (max-device-width:380px), screen and (max-width:380px) {
	/** Old MOBILEs **/

	/*contact forms*/
	#contact-form ul.options li label,
	.gform_wrapper ul.gfield_checkbox li label {max-width:210px;}

	/*homepage*/
	#home .camera_caption {font-size:0.95em;line-height:1.4em;bottom:-50% !important;}
	#home .camera_caption > div {min-width:200px;margin-left:-140px;}
	#home .camera_caption span {min-height:30px;font-size:1.2em;line-height:30px;}
}
