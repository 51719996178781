$bottom-break:0 0 30px;

$focus-bg:#d6eff6;

$base-blue:#6dc3da;
$dark-blue:#328399;
$darker-blue:#1f758c;
$turqoise:#075B71;

$base-black:#000;
$base-grey:#999;

$sp-small: 1rem;
$sp-medium: 2rem;
$sp-large: 3rem;